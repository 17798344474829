import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { ContentLayout, QuickBottomNavigationWrapper } from '../../../components/';
import Layout from '../../../components/layout';
import { GalleryList, GalleryNav, MediaPackButton } from '../../../components/Pages/Gallery/';
import SEO from '../../../components/seo';
import styles from '../../../stylesheets/pages/page.module.scss';
import {
	GALLERY_SUBSECTIONS,
	GALLERY_TYPES,
	QUICK_NAVIGATION_GALLERY,
	getGallerySectionListData,
	getLocalizedDataFromContentfulEdges,
} from '../../../utils';

const Products = () => {
	const intl = useIntl();
	const { allContentfulGallery } = useStaticQuery(graphql`
		{
			allContentfulGallery(filter: { path: { eq: "products" } }) {
				edges {
					node {
						node_locale
						name
						assets {
							... on ContentfulGalleryItem {
								name
								photo {
									fluid(maxWidth: 1200) {
										aspectRatio
										src
										srcSet
										sizes
									}
									file {
										url
										fileName
									}
								}
							}
						}
						packageDescription
						packageUrl
					}
				}
			}
		}
	`);
	const {
		node: { name, packageUrl, packageDescription, assets },
	} = getLocalizedDataFromContentfulEdges(allContentfulGallery.edges, intl.locale)[0];
	const gallerySectionListData = getGallerySectionListData(assets);
	return (
		<Layout>
			<SEO title="Newsroom - Products gallery" />
			<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_GALLERY}>
				<ContentLayout>
					<div className={styles.pageContent}>
						<GalleryNav sectionName={name} active={GALLERY_SUBSECTIONS.products} isGallerySubpage />
						{packageDescription && packageUrl && <MediaPackButton name={packageDescription} url={packageUrl} />}
						<GalleryList type={GALLERY_TYPES.default} listData={gallerySectionListData} />
					</div>
				</ContentLayout>
			</QuickBottomNavigationWrapper>
		</Layout>
	);
};

export default Products;
